.main {
  background-color: rgb(243, 243, 243);
  padding: 0vh 7vw;
  padding-top: 0px;
  a{
    text-decoration: none !important;
  }
  .events {
    overflow: hidden;
    .eventsTitle {
      margin-top: 30vh;
      //width:96%;
      height: 10rem;
      padding: 2rem 2rem;
      background-color: var(--backgroud-grey);
      color: var(--pricolor);
      border-radius: 24px;
      display: flex;
      text-align: left;
      align-items: center;
      font-size: 2rem;
      font-weight: 500;
    }
    .eventsTiles {
      margin-top: 4rem;
      display: flex;
      flex-direction: row;
      transition: transform 0.5s ease-in-out;
      text-decoration: none;
      .eventCard {
        margin: 0px 1rem;
        background-color: var(--backgroud-light);
        width: fit-content;
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        padding: 2rem;
        height: 20rem;
        color: #000;
        text-decoration: none;

        .img {
          background-color: gray;
          border-radius: 24px;
          img {
            width: fit-content;
            height:12vw;
            border-radius: 24px;
          }
        }

        .eventCardText {
          width: 20rem;
          margin-left: 2rem;
          h4 {
            font-size: 1.8rem;
          }
          .datetime {
            font-size: 0.9rem;
            padding-bottom: 0.5rem;
          }
          p {
            opacity: 0.7;
          }
        }
      }
      .card0 {
        margin-left: 0px;
      }
    }
    .slideButtons {
      margin-top: 20px;
      display: flex;
      align-items: flex-start;
      gap: 1.5rem;
      .slideButton {
        background-color: var(--backgroud-grey);
        border-radius: 12px;
        outline: none;
        border: 0px;
      }
    }
  }
  .blogs {
    display: flex;
    flex-direction: row;
    margin-top: 20vh;
    text-decoration: none;
    gap: 2rem;
    .blogTitle {
      width: 30%;
      background-color: var(--backgroud-grey);
      color: var(--pricolor);
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: 500;
    }
    .blogTiles {
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      text-decoration: none;
      .events {
        width: 100%;
        .eventsTiles {
          height: inherit;
          margin: 0%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main {
    .events {
      .eventsTiles {
        .eventCard {
          display: flex;
          flex-direction: column;

          .eventCardText {
            margin: 0px;
            width: 90%;
            overflow: hidden;
            h4 {
              font-size: 1.5rem;
            }
            .datetime {
              font-size: 0.8rem;
            }
            p {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}
