.solution {
  background-color: rgb(255, 255, 255);
  padding: 4vh 7vw;
  padding-top: 0px;

  .orange {
    color: var(--pricolor);
  }
  .getStarted {
    padding: 12px;
    display: flex;
    background-color: var(--pricolor);
    border: none;
    border-radius: 12px;
    width: fit-content;
    gap: 1rem;

    a {
      text-decoration: none;
      color: white;
      align-items: center;
    display: flex;
    gap: 1rem;
    }

    .arrow {
      margin-left: 12px;
      background-color: white;
      color: var(--pricolor);
      border-radius: 50%;
      font-size: 1em;
      padding: 2px 4px;
      //line-height: 0px;
    }
  }
  .mainContainer {
    display: flex;
    flex-direction: column;
    gap: 10vw;
    .mainBar {
      margin-top: 16em;
      .left {
        display: flex;
        flex-direction: column;
        gap: 2vw;
        padding-right: 6vw;
        h1 {
          font-size: 4em;
        }
        p{
          font-size: 1.2em;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        img {
          margin-top: -15%;
          width: 70%;
        }
      }
    }
    .cardprocessing {
      background-color: var(--backgroud-grey);
      border-radius: 24px;
      padding: 4vw 2vw;
      color: #fff;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
          line-height: 1.5em;
          font-size: 4em;
        }
        p {
          padding-right: 3.2vw;
          font-size: 1.2em;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img {
          width: 80%;
        }
      }
    }
    .apm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 2vw;
      p{
        font-size: 1.2em;
      }
    }
  }
}
