.blogDiv{
display: flex;
flex-direction: column;
margin:150px 7vw;
align-items: start;
.imageDiv{
justify-content: center;
align-items: center;
width: 100%;
 img{
    width: 100%;
    height: auto;
 }
}

}