.slideUpAnimation {
  margin-top: 16em;
  height: 2400px;
  // border: 1px red solid;
  .slideUpCont {
    position: sticky;
    top: 12em;
    .rowSlideContent {
      display: flex;
      justify-content: space-between;
    }
    .slideupleft {
      background-color: var(--backgroud-grey);
      color: #f0f0f0;
      //border: 1px white solid;
      padding: 4%;
      height: 680px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      border-radius: 12px;
      .slideUpText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        span {
          font-size: 2rem;
          font-weight: 700;
        }
      }
      .slideup1 {
        position: absolute;
        top: 72%;
        transform: translate3d(0%, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        .slideup1Txt {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .slideup2 {
        position: absolute;
        top: 82%;
        .slideup2Txt {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .slideup3 {
        position: absolute;
        top: 92%;
        .slideup3Txt {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .slideUpCard {
        background-color: var(--backgroud-grey);
        // border: 2px green solid;
        align-self: center;
        height: 30vw;
        width: 80%;
        .cardimg {
          display: flex;
          align-items: center;
          gap: 2rem;
          img {
            width: 2rem;
          }
          background-color: #292929;
          border-radius: 12px;
          padding: 3%;
          font-size: 1vw;
          font-weight: 500;
        }
        h4 {
          font-size: 1.2vw;
          font-weight: 600;
        }
        p {
          font-size: 1.1vw;
          font-weight: 400;
        }
      }
    }
    .slideupright {
      border-radius: 12px !important;
      width: 57%;
      video {
        border-radius: 12px !important;
        will-change: transform;
      }
    }
  }
}
