.aboutus {
  background-color: rgb(255, 255, 255);
  margin: 0px;
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  gap: 6vw;
  overflow-y: hidden;
  .getStarted {
    padding: 0.6rem 2rem;
    background-color: var(--pricolor);
    border: none;
    border-radius: 12px;
    width: fit-content;
    a {
      text-decoration: none;
      color: white;
      margin-right: 8px;
    }
    .arrow {
      margin-left: 12px;
      background-color: white;
      color: var(--pricolor);
      border-radius: 50%;
      font-size: 1em;
      padding: 2px 4px;
      //line-height: 0px;
    }
  }
  .orange {
    color: var(--pricolor);
  }

  .mainBar {
    width: inherit !important;
    overflow: hidden;
.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  padding: 0px;
  height: auto;
  z-index: 0; /* Place video behind content */
  transform: translate(-50%, -50%); /* Center the video */
  opacity: 20%;
  overflow: hidden;
}

.content {
  overflow: hidden;
  position: relative; /* Position relative to allow z-index */
  z-index: 1; /* Place content above video */
  background: none;
  padding: 4vh 7vw;
  display: flex;
  flex-direction: column;
  margin-top: 10em;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 2vw;

  h1 {
    font-size: 5em;
  }

  p {
    font-size: 1.2em;
  }
  .mainbarimg {
    width: 100%;
  }

}
    

  }
  .whoarewe {
    padding: 4vh 7vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 2vw;

    .head {
      border: 1px solid;
      padding: 10px 24px;
      width: max-content;
      border-radius: 24px;
      font-size: 1.2em;
    }
    .para {
      font-size: 1.2rem;
    }
    .cards {
      justify-content: space-between;
      .card {
        padding: 3vw 4vw;
        width: 49%;
        color: white;
        background-color: var(--backgroud-grey);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1vw;
        img {
          width: 20%;
        }
        h4 {
          margin: 0px;
          font-size: 1.4em;
        }
        p {
          margin: 0%;
          font-size: 0.8em;
        }
      }
    }
  }

  .experiencethediff {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--footer-color);
    color: #fff;
    gap: 2vw;
    padding: 4vw 0vw;
    h1 {
      margin: 0%;
    }
    p {
      margin: 0%;
    }
    .cardsexp {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      gap: 1vw;
      .cardexp {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1vw;
        padding: 2vw;
        width: 16vw;
        height: 16vw;
        background-color: var(--backgroud-grey);
        color: #fff;
        h4 {
          margin: 0%;
          font-size: 01em;
        }
        p {
          margin: 0%;
          font-size: 0.8em;
        }
        img {
          width: 40%;
        }
      }
    }
  }

  .paymentSpeed {
    margin: 3vw 7vw;
    margin-bottom: 2vw;
    border-radius: 64px;
    position: relative;
    z-index: 0;
    background-color: var(--backgroud-grey);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5% 24%;
    gap: 4vw;
    overflow: hidden;
    .globeImg {
      justify-self: center;
      margin-top: -10%;
      position: absolute;
      width: 40%;
      right: -10%;
    }
    h1 {
      z-index: 1;
      margin: 0%;
      font-size: 3em;
    }
    p {
      z-index: 1;
      margin: 0%;
      font-size: 1.2em;
    }
  }
}
