.developers {
  background-color: rgb(255, 255, 255);
  .getStarted {
    padding: 12px;
    background-color: var(--pricolor);
    border: none;
    border-radius: 12px;
    width: fit-content;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;

    a {
      text-decoration: none;
      color: white;
      align-items: center;
    display: flex;
    gap: 1rem;
    }

    .arrow {
      margin-left: 12px;
      background-color: white;
      color: var(--pricolor);
      border-radius: 50%;
      font-size: 1em;
      padding: 2px 4px;
      //line-height: 0px;
    }
  }
  .mainBar {
    padding: 0vh 7vw;
    display: flex;
    margin-top: 8em;
    overflow: hidden;

    .orange {
      color: var(--pricolor);
    }
    h1 {
      font-size: 4em;
    }
    p {
      font-size: 1.5em;
    }
    .left {
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: center;
    }
  }
  .pggateway {
    width: 100vw;
    margin: 0px;
    padding: 12vh 7vw;
    background-color: var(--backgroud-grey);
    color: var(--backgroud-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4vw;
    h1 {
      font-size: 4em;
      margin: 0px;
    }
    p {
      font-size: 1.2rem;
      margin: 0px;
    }
    .cards {
      //width: 100vw;
      gap: 4vw;

      .card {
        background-color: #2e2f34;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 12vw;
        height: 12vw;
        border-radius: 12px;
        gap: 2vw;
        img {
          width: 40%;
        }
        p {
          font-size: 1rem;
          color: #fff;
          font-weight: 600;
          text-align: center;
        }
      }
    }
  }
}
