.main {
  background-color: rgb(243, 243, 243);
  padding: 0vh 7vw !important;
  .nav {
  }
  .contactus {
    padding-bottom: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: auto;
    flex-direction: column;
    .contactHeader {
      color: var(--pricolor);
      padding: 5em 0em 1.5em 0em;
    }
    .contactSection {
      .sectionRow {
        display: flex;
        justify-content: space-between;
        .subleft {
          font-size: 2vw;
          background-color: #fff;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .leftsection {
            text-align: left;
          }
          .contactinfo {
            display: flex;
            flex-direction: column-reverse;
            text-align: left;
            p {
              font-size: 0.7em;
            }
            a {
              text-decoration: none;
              color: #181818;
              font-size: 1.5rem;
            }
          }
        }
        .subright {
          background-color: var(--backgroud-grey);
          color: #fff;
          .rightsection {
            text-align: left;
            h4 {
              font-size: 1.2rem;
              font-weight: 200;
              margin-bottom: 4vw;
            }
            .form {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .formpart {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              }
              input {
                margin-top: 1vw;
                border-radius: 12px;
                height: 52px;
                padding: 0px 16px;
              }
              .messageIp {
                height: 152px;
                justify-content: first baseline;
                margin-top: 1vw;
                border-radius: 12px;
                padding: 10px 16px;
              }
              button {
                margin-top: 2vw;
                border-radius: 24px;
                padding: 12px;
                background-color: var(--pricolor);
                border: none;
              }
              button:hover {
                background-color: #181818;
                color: #fff;
              }
            }
          }
        }
        .subsection {
          display: flex;
          height: 42rem;
          flex-direction: column;
          padding: 52px;
          border-radius: 48px;
          width: 49%;
        }
      }
    }
  }
}
