.footer {
  margin-top: 8em;
  margin-bottom: 2rem;
  background-color: var(--footer-color);
  width: 80vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6em 0em;
  .footerImg {
    width: 20vw;
  }
  .footerCardRow {
    margin-top: 4em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 3%;
    .footerCard {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      height: 120px;
      border-radius: 12px;
      font-size: 1rem;
      font-weight: 600;
      background-color: var(--backgroud-grey);
      color: var(--backgroud-light);
    }
  }
  .footerClick {
    width: 65%;
    overflow: hidden;
    //margin-left: 10%;
    display: flex;
    justify-content: space-around;
    .footerClickElement {
      //background-color: aqua;
      // width: 33%;
      height: 4em;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-weight: 600;
      color: white;
      font-size: 1.2em;
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
  .footerClick2 {
    margin-top: 2%;
    width: 45%;
    font-size: 0.8em;
  }
  .footerAddr{
    margin-top: 2rem;
    padding: 0px 28rem;
    text-align: center;
    color: rgba($color: #FFF, $alpha: 0.4);
  }
}
