.main {
  text-align: center;
  padding-top: 0px;
  .content {
    margin: 0 0vw;
    .cards {
      padding: 10em 0em 0em 0em;
      .card {
        border: none;
        background: none;
        .innerCard {
          background-color: var(--backgroud-light);
          height: 38em;
          padding: 0% 5%;
          box-shadow: 0 2px 30px #0000000d;
          border-radius: 12px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h1 {
            font-size: 3em;
            font-weight: 800;
          }
        }
        .videoCard {
          video {
            height: 90%;
            border-radius: 24px;
          }
        }
      }
    }
    .scrollLogoDiv {
      margin-top: 2em;
      border-radius: 12px;
      padding: 2vw 0vw;
      background-color: var(--backcolor-logo-scroll);
      color: #f0f0f0;
      overflow: hidden;
      h2 {
        font-size: 1.4em;
        padding: 0vw 0vw 2vw 0vw;
      }
      .scrollLogo {
        position: relative;
        width: 100vw;
        overflow: hidden;
        z-index: 1;
        margin: 0;
        padding: 0;
        .m-scroll {
          overflow: visible;
          height: 100%;
          white-space: nowrap;
          animation: scrollText 12s infinite linear;
          margin: 0;
          font-size: 0;
          display: flex;
          justify-content: space-between;
          width: fit-content;
          span {
            display: inline-block;
            margin: 0;
            padding: 0 4vw;
            color: white;
            img {
              filter: grayscale(1);
              width: 6vw;
            }
          }
        }
      }
    }
    .textshop {
      z-index: 1;
      align-items: center;
      width: 100%;
      font-size: 2vw;
      height: 40%;
      max-width: 20em;
      display: flex;
      justify-content: center;
      margin-top: 8em;
      position: sticky;
      top: 8em;
      font-weight: 800;
    }
    .floatCardsDiv {
      //margin-bottom: 12em;
      width: 100%;
      // border: 1px wheat solid;
      height: 1400px;
      .rowFloatCards {
        position: sticky;
        // border: 1px red solid;
        top: 16em;
        display: flex;
        justify-content: space-between;
        .card {
          will-change: transform;
          gap: 32px;
          color: #f0f0f0;
          background-color: rgb(33, 33, 33);
          border-radius: 16px;
          svg {
            width: 7em;
            filter: brightness(0) saturate(100%) invert(48%) sepia(47%) saturate(6513%) hue-rotate(352deg) brightness(101%) contrast(101%);
          }
          height: 32rem;
          width: 30%;
          padding: 2%;
          display: flex;
          justify-content: center;
          align-items: center;
          h3 {
            font-size: 3rem;
            font-weight: 700;
          }
          p {
            font-size: 1vw;
          }
        }

        .right {
          transform: translate3d(-5vw, 42vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(7deg) skew(0deg, 0deg);
          transform-style: preserve-3d;
        }
        .mid {
          transform: translate3d(0px, 37vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
          transform-style: preserve-3d;
        }
        .left {
          transform: translate3d(5vw, 42vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-7deg) skew(0deg, 0deg);
          transform-style: preserve-3d;
        }
      }
    }

    .paynow {
      margin-top: 6%;
      background-color: var(--pricolor);
      height: 700px;
      color: var(--bacground-drk);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 64px;
      .paynowtext {
        font-size: 10em;
        font-weight: 700;
      }
      .paynowbuttons {
        width: 40%;
        display: flex;
        justify-content: space-around;
        .button1,
        .button2 {
          border-radius: 12px;
          height: 60px;
          width: 200px;
        }
      }
    }
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}
