.customNav {
  z-index: 9999999;
  transition: opacity 1.5s ease, transform 1.5s ease;
  background-color: var(--backgroud-light);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .elements {
    position: fixed;
    z-index: 10;
    box-shadow: 0 2px 30px #0000000d;
    padding: 0px 10px 0px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 86vw;
    margin-top: 10em;
    border-radius: 30px;
    font-size: 16px;
    .left {
      img {
        width: 200px;
        //filter: brightness(0) saturate(100%) invert(21%) sepia(61%) saturate(5219%) hue-rotate(353deg) brightness(89%) contrast(102%);
      }
    }

    .right {
      float: right;
      ul {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        list-style-type: none;
        margin: 0%;
        .orangeButton{
          background-color: var(--pricolor);;
          color: black;
          //padding: 20px 20px;
          border-radius: 24px;
                  }
        .orangeButton:hover{

        }
        li {
          padding:30px 30px;
          a {
            text-decoration: none;
            font-weight: 600;
            color: inherit;
          }
          
        }
        
      }
    }
  }
}
