/* global.css */
:root {
  --pricolor: #FF5314;
  --backcolor-logo-scroll: rgb(33, 33, 33);
  --bacground-drk: #000;
  --backgroud-light: #fff;
  --backgroud-grey: rgb(33, 33, 33);
  --footer-color: #181818;
  --violet:#743FEA;

}

body {
  margin: 0;
  font-family: 'Poppins' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(243, 243, 243);
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}